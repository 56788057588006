<template>
<div class="about">
  <div class="about_logo">
    <img src="../../assets/yun_icu_logo.png" style="width: 100px;height:100px;border-radius: 100px" alt="">
    <h3 style="color: #5EA5E0">让学习、诊疗更便捷！</h3>
    <h4 style="color: #909399">版本5.4.2</h4>
  </div>
<!--  <div style="font-weight: bold">-->
<!--    <van-cell title="云ICU价值观及其行为准则" is-link value="" @click="openUrl" />-->
<!--    <van-cell title="去评分" is-link value="" @click="openApp"/>-->
<!--  </div>-->
</div>
</template>

<script>
export default {
  name: "aboutIcu",
  data () {
    return {
    }
  },
  methods: {
    // openUrl(){
    //   this.$router.push({path:'userAgreement'})
    // },
    // openApp() {
    //   const u = navigator.userAgent;
    //   const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    //   if (isiOS) {
    //     window.location = 'https://apps.apple.com/cn/app/%E4%BA%91icu/id1062118952'
    //   } else {
    //     window.location = 'https://sj.qq.com/appdetail/com.ian.icu'
    //   }
    // },
  }


}
</script>

<style lang="scss" scoped>
.about{
  background-color: #FFFFFF;
  .about_logo{

    align-items: center;
    margin: 5% auto 0;
    text-align: center;
  }
}
</style>
